import React, { useEffect, useState } from "react";
import { getCategories } from "../../../API/Question";
import { useHistory } from "react-router-dom";
import GoogleAds from "../../../GoogleAds";

const Search = "https://playerstorage.b-cdn.net/quiztwiz/assets/Search.svg";

const SmallCat = (props) => {
  const [search, setSearch] = useState("");
  const [cateData, setCateData] = useState([]);
  const [searchText, setSearchText] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const history = useHistory();

  const enteringData = (event) => {
    setSearch(event.target.value);
    setSearchText(true);
    if (event.target.value === "") {
      setSearchText(false);
    }
  };

  const goToGame = (id) => {
    props.changeCategoryId(id);
    history.push(`/home/quizzes-for-category`);
  };

  useEffect(() => {
    let dataCategory = async () => {
      let categatoryData = await getCategories();
      setCateData(categatoryData?.data?.data);
    };
    dataCategory();
  }, []);

  const clearSearch = () => {
    setSearch("");
    setSearchText(false);
  };

  const toggleShowMore = () => {
    setShowMore((prev) => !prev);
  };

  const filteredData = cateData.filter((e) => {
    if (search === "") {
      return e;
    } else if (e.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
      return e;
    }
  });

  const visibleData = showMore ? filteredData : filteredData.slice(0, 4);

  return (
    <>
      <div className="flex flex-col gap-6 w-full">
        <div className="text-lg font-bold text-center">
          Select the Quiz category that you want to play
        </div>
        <div className="border-2 border-white rounded-full px-4 py-3 flex gap-2">
          <img src={Search} alt="search" />
          <input
            className="bg-transparent text-lg outline-none w-full"
            type="text"
            placeholder="Search Quiz Category"
            value={search}
            onChange={enteringData}
          />
          {searchText ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-7 w-7"
              onClick={clearSearch}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            ""
          )}
        </div>
        <div className="grid grid-cols-2 gap-3">
          {visibleData.map((data) => (
            <div key={data._id}>
              <div
                onClick={() => goToGame(data._id)}
                className="flex gap-1 items-center border-[1px] border-white rounded-full p-2 cursor-pointer"
              >
                <img
                  className="w-[46px] rounded-full"
                  src={data?.img || data?.image}
                  alt="category"
                />
                <span className="w-full text-center text-sm">
                  {data.name}
                </span>
              </div>
            </div>
          ))}
        </div>
        {filteredData.length > 4 && (
          <button
            onClick={toggleShowMore}
            className="text-center text-lg text-blue-500"
          >
            {showMore ? "Show Less" : "Show More"}
          </button>
        )}
      </div>
    </>
  );
};

export default SmallCat;
