import React, { useState, useEffect } from 'react';
import { FaLanguage , FaBook , FaLandmark, FaChevronRight, FaChevronLeft,FaWater,FaBookOpen ,FaGuitar,FaGamepad  } from 'react-icons/fa';
import story1 from "../../../Assets/story/story1.png"
import story2 from "../../../Assets/story/story2.png"
import story3 from "../../../Assets/story/story3.png"
import story4 from "../../../Assets/story/story4.png"
import story5 from "../../../Assets/story/story5.png"
import GoogleAds from '../../../GoogleAds';
import useAnalyticsEventTracker from '../../../useAnalyticsEventTracker';

const scienceFacts = [
  "Bananas are radioactive: Bananas contain potassium-40, a radioactive isotope of potassium. While the levels are low and harmless, this makes bananas slightly radioactive.",
  "Water can boil and freeze at the same time: This phenomenon, called the triple point, occurs when temperature and pressure conditions are just right for all three phases (gas, liquid, and solid) of a substance to coexist in thermodynamic equilibrium.",
  "Humans share 60% of their DNA with bananas: Despite our vast differences, humans and bananas share a surprising amount of genetic material.",
  "A day on Venus is longer than a year on Venus: Venus takes about 243 Earth days to rotate once on its axis but only about 225 Earth days to complete an orbit around the Sun.",
  "Hot water freezes faster than cold water: Known as the Mpemba effect, this counterintuitive phenomenon occurs under certain conditions and is still not fully understood.",
  "There are more stars in the universe than grains of sand on all the Earth’s beaches: Estimates suggest there are 10^21 stars in the observable universe, far surpassing the number of grains of sand on Earth.",
  "A single teaspoon of honey represents the life work of 12 bees: Bees work incredibly hard to produce honey, with each bee contributing a tiny fraction of the final amount.",
  "The Eiffel Tower can be 15 cm taller during the summer: Due to thermal expansion, the iron structure of the Eiffel Tower expands in the heat, making it slightly taller.",
  "Your nose can remember 50,000 different scents: The human nose is capable of distinguishing a vast array of different smells, contributing to our sense of taste and memory.",
  "Octopuses have three hearts: Two hearts pump blood to the gills, while the third pumps it to the rest of the body. Their blue blood is due to a copper-based molecule called hemocyanin."
];
const geographyFacts = [
  "Mount Everest isn't the tallest mountain from base to peak: While Mount Everest is the highest point on Earth above sea level, Mauna Kea in Hawaii is the tallest mountain when measured from its underwater base to its summit.",
  "There are 195 countries in the world today: This total includes 193 member countries of the United Nations and 2 observer states, the Holy See and Palestine.",
  "Russia spans 11 time zones: As the largest country in the world, Russia covers a vast area that stretches across 11 time zones.",
  "The world's largest desert isn't the Sahara: Antarctica is technically the world's largest desert because it receives very little precipitation.",
  "Canada has the longest coastline of any country: With a coastline of over 202,080 kilometers (125,567 miles), Canada boasts the longest coastline in the world.",
  "Africa is the only continent located in all four hemispheres: Africa is situated in the Northern, Southern, Eastern, and Western Hemispheres.",
  "Lake Superior is the largest freshwater lake by surface area: Located in North America, Lake Superior is the largest freshwater lake in the world by surface area.",
  "The Dead Sea is the lowest point on Earth's surface: The surface and shores of the Dead Sea are about 430 meters (1,410 feet) below sea level, making it the lowest land elevation on Earth.",
  "There are over 7,000 islands in the Philippines: The Philippines is an archipelago consisting of more than 7,000 islands.",
  "Greenland is the world's largest island: While Australia is considered a continental landmass, Greenland is the largest island in the world, covering an area of over 2.16 million square kilometers (836,331 square miles)."
];

const historyFacts = [
  "Cleopatra lived closer in time to the moon landing than to the construction of the Great Pyramid of Giza: The Great Pyramid was built around 2560 BCE, while Cleopatra lived around 69-30 BCE. The moon landing occurred in 1969 CE.",
  "Oxford University is older than the Aztec Empire: Teaching began at Oxford University in 1096, while the Aztec Empire was founded in 1428.",
  "Leonardo da Vinci could write with one hand and draw with the other at the same time: Leonardo da Vinci was known for his exceptional talents and ambidextrous abilities.",
  "The shortest war in history lasted only 38 minutes: The Anglo-Zanzibar War of 1896 holds the record for the shortest war in history.",
  "The Eiffel Tower was originally intended for Barcelona: Gustave Eiffel initially proposed the design to Barcelona, but the city rejected it. It was then built in Paris for the 1889 Exposition Universelle.",
  "Ancient Romans used urine as mouthwash: Urine contains ammonia, which was used by the Romans to clean their teeth and whiten them.",
  "President John F. Kennedy donated his presidential salary: Kennedy's annual salary of $100,000 was donated to charity during his time in office.",
  "During World War II, a Great Dane named Juliana was awarded the Blue Cross Medal: She extinguished an incendiary bomb by urinating on it, thereby saving lives.",
  "The Leaning Tower of Pisa took nearly 200 years to build: Construction started in 1173 and wasn't completed until 1372 due to interruptions caused by wars and structural issues.",
  "The first-ever marathon was inspired by an ancient Greek soldier: According to legend, the soldier Pheidippides ran from the battlefield of Marathon to Athens, a distance of about 26 miles, to announce the Greek victory over the Persians."
];

const entertainmentFacts = [
  "Walt Disney's first original character was not Mickey Mouse: Before Mickey Mouse, Walt Disney created a character called Oswald the Lucky Rabbit, but he lost the rights to Oswald in a contract dispute.",
  "The Hollywood sign originally read 'Hollywoodland': The famous Hollywood sign was initially an advertisement for a real estate development and included the word 'land' at the end.",
  "The longest-running TV show is a news program: 'Meet the Press,' an American news and interview program, has been on the air since 1947.",
  "The Beatles hold the record for the most number-one hits on the Billboard Hot 100: The Beatles have 20 number-one hits, more than any other artist or group.",
  "The first film to gross over $1 billion at the box office was 'Titanic': Directed by James Cameron, 'Titanic' reached this milestone in 1997.",
  "The world's highest-grossing movie adjusted for inflation is 'Gone with the Wind': When adjusted for inflation, this 1939 classic surpasses even modern blockbusters in box office earnings.",
  "'The Simpsons' is the longest-running animated TV show: Since its debut in 1989, 'The Simpsons' has become the longest-running animated series in American television history.",
  "A 'James Bond' movie scene once caused a real government investigation: In 'From Russia with Love,' a scene featuring a fictional gadget led to an actual investigation by the KGB, who believed it might reveal real espionage techniques.",
  "'E.T. the Extra-Terrestrial' was inspired by an imaginary friend: Director Steven Spielberg said the story of 'E.T.' was based on an imaginary friend he created after his parents' divorce.",
  "The first video ever uploaded to YouTube was about elephants: The video, titled 'Me at the zoo,' was uploaded by co-founder Jawed Karim on April 23, 2005, and featured him talking about elephants at the San Diego Zoo."
];



const foodFacts = [
  "Honey never spoils: Archaeologists have found pots of honey in ancient Egyptian tombs that are over 3,000 years old and still perfectly edible.",
  "Apples belong to the rose family: Along with pears, plums, cherries, and peaches, apples are members of the Rosaceae (rose) family.",
  "Bananas are berries, but strawberries aren't: Botanically, bananas qualify as berries, while strawberries do not because their seeds are on the outside.",
  "Ketchup was once sold as medicine: In the 1830s, ketchup was marketed as a cure for indigestion by an Ohio physician named Dr. John Cook Bennett.",
  "Pineapples take about two years to grow: From planting to harvesting, it takes around 18-24 months for a pineapple to mature.",
  "The most expensive pizza in the world costs $12,000: The Louis XIII pizza, created by Chef Renato Viola in Italy, features toppings like caviar, lobster, and 24-karat gold flakes.",
  "Potatoes were the first food to be grown in space: In 1995, NASA and the University of Wisconsin-Madison developed technology to grow potatoes in space.",
  "White chocolate isn't really chocolate: White chocolate doesn’t contain cocoa solids (the key ingredient in chocolate) but is made from cocoa butter, sugar, and milk solids.",
  "Watermelons are 92% water: This high water content makes watermelons not only delicious but also hydrating.",
  "Peanuts are not nuts: Peanuts are legumes, which means they belong to the same family as beans and lentils."
];

const sportsFacts = [
  "The Olympic Games used to award medals for art: Between 1912 and 1948, the Olympics held competitions in fine arts, including painting, sculpture, architecture, literature, and music.",
  "Golf is the only sport to have been played on the moon: In 1971, astronaut Alan Shepard hit two golf balls on the lunar surface during the Apollo 14 mission.",
  "The longest tennis match lasted 11 hours: In 2010, John Isner and Nicolas Mahut played a first-round match at Wimbledon that lasted 11 hours and 5 minutes, spread over three days.",
  "Basketball was originally played with a soccer ball: When the sport was first invented in 1891, players used a soccer ball, and the hoops were peach baskets.",
  "Cricket is played in over 100 countries: While often associated with countries like England, Australia, and India, cricket is played professionally in over 100 countries around the world.",
  "A baseball used in Major League Baseball lasts only 5 to 7 pitches: On average, a baseball is replaced every 5 to 7 pitches during a game, leading to approximately 70 balls used per game.",
  "The Stanley Cup has been to the bottom of a swimming pool: The NHL's Stanley Cup has a history of odd adventures, including being accidentally dropped into a swimming pool during celebrations.",
  "The highest-scoring soccer game was 149-0: In 2002, Madagascar's AS Adema won 149-0 against SO l’Emyrne, as the losing team deliberately scored own goals in protest against a refereeing decision.",
  "Marathons are 26.2 miles because of the British royal family: The marathon distance was standardized to 26.2 miles in the 1908 London Olympics to cover the distance from Windsor Castle to the Olympic Stadium, plus a bit extra to finish in front of the royal box.",
  "Formula 1 cars can drive upside down: At high speeds, the aerodynamic downforce generated by a Formula 1 car could theoretically allow it to drive upside down on the ceiling of a tunnel."
];





const categories = [
  { name: 'Cuisine', icon: story1, facts: foodFacts , id:"123" },

  { name: 'Geography', icon: story4, facts: geographyFacts,  id:"1234"  },
  { name: 'Science', icon: story5, facts: scienceFacts,  id:"12312"  },
  
  { name: 'Entertainment', icon: story3, facts: entertainmentFacts,  id:"123321"  },
  {name:"Sports", icon:story2, facts: sportsFacts,  id:"123asd" }
];

const FunFacts = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentFactIndex, setCurrentFactIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const numStories = categories.length;
  const angleStep = 360 / numStories;
  const [currentIndex, setCurrentIndex] = useState(0);
  let cardsPerPage = 4;
  const trackEvent = useAnalyticsEventTracker();
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => 
      (prevIndex + cardsPerPage >= categories.length) ? 0 : prevIndex + cardsPerPage
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      (prevIndex - cardsPerPage < 0) ? Math.max(categories.length - cardsPerPage, 0) : prevIndex - cardsPerPage
    );
  };
  useEffect(() => {

    let timer;
    if (isModalOpen && selectedCategory) {
      timer = setInterval(() => {
        setCurrentFactIndex((prevIndex) => {
          if (prevIndex + 1 < selectedCategory.facts.length) {
            return prevIndex + 1;
          } else {
            setIsModalOpen(false);
            return 0;
          }
        });
      }, 7000);
    }
    return () => clearInterval(timer);
  }, [isModalOpen, selectedCategory]);

  const handleCategoryClick = (category) => {
    trackEvent(
      "FunFacts card clicked",
      "Uer clicked FunFacts card ",
      "FunFacts Card clicked"
    );
    setSelectedCategory(category);
    setCurrentFactIndex(0);
    setIsModalOpen(true);
  };                                                                                    
  const nextstory =() => {
    setCurrentFactIndex((prevIndex) => {
      if (prevIndex + 1 < selectedCategory.facts.length) {
        return prevIndex + 1;
      } else {
        setIsModalOpen(false);
        return 0;
      }
    });
  }
  const slicedCategories = categories.slice(currentIndex, currentIndex + cardsPerPage);
const lastIndex = currentIndex + slicedCategories.length - 1;
  return (
    <div className="relative w-full">
      <h1 className="text-2xl font-bold mb-4">Fun Facts 💡</h1>
      <div className="flex space-x-4 p-2 sm:gap-4 gap-4  rounded overflow-x-scroll scrollhide scroll-smooth">

        {categories.map((category, index) => {
            const rotateAngle = angleStep * index;
            
         return <>
        
          <button
            key={category.name}
            onClick={() => handleCategoryClick(category)}
           
            className="relative"
            // style={{ filter: `blur(${index === lastIndex ? "12px" : "0px"})` }}
           
          >
            <div className="w-16 h-16 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 ">
              {/* <div className="w-full h-full rounded-full bg-white flex items-center justify-center">
                <span className="text-3xl"><img src={category.icon} /></span>
              </div> */}
              {/* Story-like outer boundary */}
              
                <img src={category.icon} />
            </div>
            <p className="text-xs mt-1 text-center">{category.name}</p>
          </button>
         </>
})}
{/* <button 
          onClick={prevSlide}
          className="absolute left-[-25px] top-[60%] transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md"
        >
          <FaChevronLeft className="text-gray-600" />
        </button>
        <button 
          onClick={nextSlide}
          className="absolute right-[-8px] top-[60%] transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md"
        >
          <FaChevronRight className="text-gray-600" />
        </button> */}
      </div>
      
      {/* Gradient definition for SVG */}
      <svg width="0" height="0">
  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" stopColor="#8B5CF6" />
      <stop offset="100%" stopColor="#EC4899" />
    </linearGradient>
  </defs>
</svg>


      {isModalOpen && selectedCategory && (
        <div className="fixed z-50 inset-0 bg-black bg-opacity-50 flex items-center justify-center text-black" onClick={() => nextstory()}>
        
          <div className="bg-white w-[500px]  shadow-lg p-6 m-4  ounded-lg text-lg">
            <div className='flex justify-between items-center'>

            <h2 className="text-xl font-semibold mb-4">{selectedCategory.name}</h2>
            <button type="button" class="text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:focus:ring-yellow-900">Tap for next</button>
            </div>
            <p className="mb-4 shadow-[0_10px_20px_rgba(240,_46,_170,_0.7)] rounded-xl p-4">{selectedCategory.facts[currentFactIndex]}</p>
            <div className="flex justify-between mb-4">
              {selectedCategory.facts.map((_, index) => (
                <div
                  key={index}
                  className={`h-1 flex-grow mx-0.5 rounded-full ${
                    index <= currentFactIndex ? 'bg-blue-500' : 'bg-gray-300'
                  }`}
                ></div>
              ))}
            </div>
            <div className=" max-w-[320px] max-h-[50px] ">
          <GoogleAds funfacts={true} />
              </div>  
            <button
              onClick={() => setIsModalOpen(false)}
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded-full"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FunFacts;